<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="caption d-flex justify-center">
        <h2>{{$_strings.order.VIEW_STATUS_CARGO}} {{cargoNumber}}</h2>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>
        <v-row v-else-if="isError" class="mt-5">
          <v-col cols="12">
            <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon @click="fetchCargoStatus($route.params.id)">
              mdi-reload
            </v-icon>
          </v-col>
        </v-row>
        <section v-else-if="cargoStatus.length" style="margin-left:-40px">
          <v-timeline v-for="(item, index) in cargoStatus" dense align-top :key="index">
            <v-timeline-item color="blue lighten-2" fill-dot small right>
              <v-row>
                <v-col class="ma-0 pa-0">
                  <p class="grey--text mb-0">
                    {{dateFormat(item.statusDateTime)}}
                  </p>
                  <p class="caption black--text mb-0">{{item.status.includes('_') ? statusOrder(item.status) : item.status}}</p>
                  <p class="caption grey--text">{{item.notes}}</p>
                </v-col>
                <v-col cols="auto" class="grey--text">{{timeFormat(item.statusDateTime)}}</v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </section>
        <section v-else>
          <v-col cols="12" class="text-center grey--text">
            <h1>{{this.$_strings.common.NO_DATA}}</h1>
          </v-col>
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchCargoStatus();
      }
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isError: false,
      cargoStatus: [],
      shipmentsCargoId: null,
      cargoNumber: null,
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    async fetchCargoStatus() {
      try {
        this.isLoading = true;
        this.isError = false;
        const result = await this.$_services.order.cargoStatus(this.shipmentsCargoId);
        this.cargoStatus = result.data;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
